import { ErrorDetails } from './error-details';

export class ErrorModel {

  public Time: string;
  
  public get ErrorMessage() : string {
    return this.Error as string;
  }

  public get ErrorDetails() : ErrorDetails {
    return this.Error as ErrorDetails;
  }

  constructor(
      public Error: string | ErrorDetails,
      public Translatable?: boolean
  ) {
    this.Time = new Date().toTimeString();
    
    if (!!(Error as ErrorDetails)) {
      this.Translatable = true;
    } else {
      this.Translatable = !!this.Translatable;
    }
  }
}
