import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { first } from 'rxjs/operators';
import { LandfolioLookupTables } from 'src/app/enums/lookup-table-enum';
import { SelectMode } from 'src/app/enums/select-mode';
import { LookupModel } from 'src/app/models';
import { CustomDialogConfig } from 'src/app/models/custom-dialog-config';
import { SelectableListItem } from 'src/app/models/selectable-list-item';
import { AssetListWidgetFacade } from 'src/app/facade/deprecated/asset-list-widget.facade';
import { LookupCacheService } from 'src/app/services/deprecated/lookup-cache.service';
import { AssetListViewDTO } from '../../../../../../data-transfer-objects/asset/asset-list-view-dto';
import { RelatedAssetListWidgetAdditionalConfigurationData } from '../../../../../../data-transfer-objects/configuration/widgets/related-asset-list-widget-configuration-view-dto';
import { ToastService } from '../../../../../../services/deprecated/toast.service';
import { AbstractAssetListFacade } from '../../../../../../facade/abstract/abstract-asset-list.facade';
import { BaseAssetListWidgetComponent } from '../base-asset-list-widget.component';
import { IListWidget, NavigateWrapper } from '../list-widget-interface';
import { StyleVariants } from '../../../../../../enums/bootstrap/style-variants';

@Component({
    selector: 'fw-asset-list-with-relationship-widget',
    templateUrl: './asset-list-with-relationship-widget.component.html',
    providers: [
        { provide: AbstractAssetListFacade, useClass: AssetListWidgetFacade },
    ],
})
export class AssetListWithRelationshipWidgetComponent
    extends BaseAssetListWidgetComponent
    implements OnInit, OnDestroy, IListWidget<AssetListViewDTO>
{
    public showOptionItems: boolean = false;
    public relationshipTypes: LookupModel[] = [];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private selectedListModels: any;
    
    public selectedRelationShipTypes: {[assetId: string]: string} = {};

    public get addEnabled(): boolean {
        return this.AssetListWidgetConfigurationViewDTO.AddEnabled;
    }

    public get selectMode(): SelectMode {
        return this.AssetListWidgetConfigurationViewDTO.SelectMode;
    }

    public get deleteEnabled(): boolean {
        return this.AssetListWidgetConfigurationViewDTO.DeleteEnabled;
    }

    public get canProceed(): boolean {
        if (this.selectMode === SelectMode.Single) {
            return this.selectedListModels;
        } else if (
            this.selectedListModels &&
            this.selectMode === SelectMode.Multiple
        ) {
            return this.selectedListModels.length > 0;
        }
    }

    constructor(
        private dynamicDialogReference: DynamicDialogRef,
        dynamicDialogConfig: DynamicDialogConfig,
        abstractAssetListFacade: AbstractAssetListFacade,
        toastService: ToastService,
        router: Router,
        cdr: ChangeDetectorRef,
        lookupCacheService: LookupCacheService
    ) {

        super(abstractAssetListFacade, cdr, router, toastService);

        lookupCacheService
            .GetLandfolioLookupTable(
                LandfolioLookupTables[LandfolioLookupTables.RelationshipType]
            )
            .pipe(first())
            .subscribe((lookupModels: LookupModel[]) => {
                this.relationshipTypes = lookupModels;
            });

        if (dynamicDialogConfig instanceof CustomDialogConfig) {
            const additionalConfigurationData: RelatedAssetListWidgetAdditionalConfigurationData =
                (
                    dynamicDialogConfig as CustomDialogConfig<
                        RelatedAssetListWidgetAdditionalConfigurationData>
                ).configuration;

            this.AssetListWidgetConfigurationViewDTO =
                additionalConfigurationData.AssetListWidgetConfigurationViewDTO;
            this.showOptionItems = additionalConfigurationData.ShowOptionItems;
        }
    }


    public NavigateByContext(navigateWrapper: NavigateWrapper): void {
        //override navigation
        this.toastService.ShowToast([
            {
                Message: 'RelatedAsset.Behaviours.Unsupported',
                RouterLink: null,
                RouterText: null,
                QueryParameters: null,
                MessageParameters: {},
            },
        ], StyleVariants.Danger);
    }

    public OnDataBind() {
        this.paginationResultModelCopy.Models.forEach((model) => {
            if (model.Id in this.selectedRelationShipTypes) {
                model.SelectedRelationshipTypeId = this.selectedRelationShipTypes[model.Id];
            }
        });
    }
    
    public onChange(assetId: string, relationshipTypeId: string) {
        this.selectedRelationShipTypes[assetId] = relationshipTypeId;
    }

    public cancelClicked() {
        this.dynamicDialogReference.close();
        this.dynamicDialogReference.destroy();
    }

    public proceedClicked() {
        const selectedItems: SelectableListItem[] = [];

        if (this.selectMode === SelectMode.Single) {
            const selectableItem: SelectableListItem = {
                id: this.selectedListModels.Id,
                selected: true,
                value: this.selectedListModels.SelectedRelationshipTypeId,
            };
            selectedItems.splice(0, 1, selectableItem);
        } else if (this.selectMode === SelectMode.Multiple) {
            this.selectedListModels.forEach((selectedItem) => {
                const selectableItem: SelectableListItem = {
                    id: selectedItem.Id,
                    selected: true,
                    value: selectedItem.SelectedRelationshipTypeId,
                };

                selectedItems.push(selectableItem);
            });
        }

        this.dynamicDialogReference.close(selectedItems);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public itemSelected(selectedItems: any) {
        this.selectedListModels = selectedItems;
    }

    public clearSelectedItems() {
        this.selectedListModels = undefined;
        this.selectedRelationShipTypes = {};
    }
}
