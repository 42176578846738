import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DocumentListViewDTO } from '../../../../data-transfer-objects/document/document-list-view-dto';
import { FormEditStyle } from '../../../../enums/configuration/form-edit-style';
import { DocumentOperation } from '../../../../enums/document-operation';
import { LandfolioLookupTables } from '../../../../enums/lookup-table-enum';
import { BytePipe } from '../../../../pipes/byte.pipe';
import { DocumentApplicationServiceService } from '../../../../services/application/document-application-service.service';
import { LookupCacheService } from '../../../../services/deprecated/lookup-cache.service';
import { DocumentRowOptions } from './document-row-options';

@Component({
    selector: 'fw-document-row',
    templateUrl: './document-row.component.html',
})
export class DocumentRowComponent {
    public set isLoading(value: boolean) {
        this._isLoading = value;
    }

    public get isLoading(): boolean {
        return this._isLoading;
    }

    public get hasFileUploaded(): boolean {
        return this.DocumentListViewDTO.Size > 0;
    }

    public documentOperation = DocumentOperation;
    public documentType: string = '';
    public formEditStyle = FormEditStyle;
    public isOperationInProgress: boolean = false;

    @Input()
    public PageId: string;

    @Input()
    public DocumentListViewDTO: DocumentListViewDTO;

    @Input()
    public DocumentRowOptions: DocumentRowOptions;

    @Output()
    public ReplaceDocumentEvent: EventEmitter<DocumentListViewDTO> = new EventEmitter<DocumentListViewDTO>();

    @Output()
    public UploadDocumentEvent: EventEmitter<DocumentListViewDTO> = new EventEmitter<DocumentListViewDTO>();

    @Output()
    public RemoveDocumentEvent: EventEmitter<string> = new EventEmitter<string>();

    private _isLoading: boolean = false;

    constructor(
        private documentApplicationService: DocumentApplicationServiceService,
        private lookupService: LookupCacheService
    ) { }

    public get canDownloadDocument(): boolean {
        return this.operationEnabled(DocumentOperation.Download) && this.DocumentListViewDTO.IsDownloadable;
    }

    public displayField(fieldName: string): boolean {
        return (
            this.DocumentRowOptions.fields.findIndex(
                (field) => field === fieldName
            ) !== -1
        );
    }

    public dowloadDocument(): void {
        this.documentApplicationService.downloadDocument(
            this.DocumentListViewDTO.Id,
            this.DocumentListViewDTO.FileName,
            this.PageId
        );
    }

    public formatDocumentSize(value: number): string {
        const bytePipe = new BytePipe();

        return bytePipe.transform(value, 2);
    }

    public getDocumentTypeLookupValue(): string {
        let documentType: string = '';

        this.lookupService
            .GetLandfolioLookupValue(
                LandfolioLookupTables.DocumentType,
                this.DocumentListViewDTO.DocumentTypeId
            )
            .subscribe((documentTypeLookupValue: string) => {
                documentType = documentTypeLookupValue;
            });

        return documentType;
    }

    public operationEnabled(operation: DocumentOperation): boolean {
        return (
            this.DocumentRowOptions.documentOperations.includes(operation) &&
            !this.isLoading
        );
    }

    public replaceDocument(): void {
        this.ReplaceDocumentEvent.emit(this.DocumentListViewDTO);
    }

    public uploadDocument(): void {
        this.UploadDocumentEvent.emit(this.DocumentListViewDTO);
    }

    public deleteDocument(): void {
        this.RemoveDocumentEvent.emit(this.DocumentListViewDTO.Id);
    }
}
