<div class="topic-details">
    <div class="topic-header">
        <span class="topic-title">{{discussion?.Topic}}</span>
        <div class="topic-date">
            <span>{{"Widget.Discussion.Opened" | transloco}}</span>
            <span class="timestamp">{{discussion?.Opened | localeDateFormat: { stringDate: true } }}</span>
        </div>
    </div>
    <div class="topic-participants">
        <span class="pi pi-users"></span>
        <span class="participants-header">{{"Widget.Discussion.Participants" | transloco}}</span>
        <span class="participants">{{formattedParticipants}}</span>
    </div>

    <div class="new-comment">
        <p-editor ngDefaultControl [(ngModel)]="discussionComment.Comment"></p-editor>
        <div class="buttons">
            <fw-file-upload [(UploadedOutputFiles)]="UploadedOutputFiles"></fw-file-upload>
            <div class="new-documents"></div>
            <p-button [disabled]="!commentExists()" styleClass="btn disc-btn-submit btn-lg" type="button" label="{{AddDiscussionCommentButtonResourceId | transloco}}" (onClick)="addComment()"></p-button>
        </div>
    </div>
</div>