<div class="topic-list">
    <fw-list (OnLoad)="OnLoad($event)"
             (SelectableFieldClicked)="LoadTopic($event)"
             [ListConfigurationViewDTO]="DiscussionListWidgetConfigurationViewDTO"
             [ErrorMessageResourceId]="errorMessageResourceId"
             [PaginationResultModel]="PaginationResultModel"
             [IsUserList]="false"
             [IsSelectable]="true"
             [selectMode]="selectMode"
             [autoSelectFirstItem]="true"
             [IsPageable]="false"
             [CustomHeaderTemplate]="CustomListHeaderTemplate"
             [CustomBodyTemplate]="CustomListBodyTemplate">
    </fw-list>
    <p-button styleClass="btn btn-primary" type="button" label="{{DiscussionListWidgetConfigurationViewDTO.NewDiscussionButtonResourceId | transloco }}" (onClick)="newDiscussionClicked()"></p-button>
</div>


<ng-template #CustomListHeaderTemplate>
    <th>
        {{DiscussionListWidgetConfigurationViewDTO.HeaderResourceId | transloco}}
    </th>
</ng-template>

<ng-template #CustomListBodyTemplate let-data>
    <td>
        <div class="d-flex">
            <span class="topic-name" title="{{data['Topic']}}">{{data['Topic']}}</span>
            <span class="topic-date">{{data['LastUpdated'] | localeDateFormat: { stringDate: true } }}</span>
        </div>
        <div class="topic-comment" title="{{StripHtml(data['LatestComment'])}}">{{StripHtml(data['LatestComment'])}}</div>
    </td>
</ng-template>



