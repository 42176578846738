import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    QueryList,
    SimpleChanges,
    ViewChildren
} from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Subscription } from 'rxjs';
import {
    severity_success,
    severity_warning
} from '../../../../../consts/severity-options';
import { DocumentListWidgetConfigurationViewDTO } from '../../../../../data-transfer-objects/configuration/document-list-widget-configuration-view-dto';
import { DocumentUploadConfigurationViewDTO } from '../../../../../data-transfer-objects/configuration/document-upload-configuration-view-dto';
import { DocumentListViewDTO } from '../../../../../data-transfer-objects/document/document-list-view-dto';
import { DocumentViewDTO } from '../../../../../data-transfer-objects/document/document-view-dto';
import { StyleVariants } from '../../../../../enums/bootstrap/style-variants';
import { FormEditStyle } from '../../../../../enums/configuration/form-edit-style';
import { DocumentOperation } from '../../../../../enums/document-operation';
import { LandfolioLookupTables } from '../../../../../enums/lookup-table-enum';
import { FileHelper } from '../../../../../helpers/file-helper';
import { GuidHelper } from '../../../../../helpers/guid-helper';
import { LookupModel } from '../../../../../models';
import { BytePipe } from '../../../../../pipes/byte.pipe';
import {
    DialogApplicationService,
    DialogOptions
} from '../../../../../services/application/dialog-application.service';
import { ToastApplicationService } from '../../../../../services/application/toast-application.service';
import { ConfigurationHttpService } from '../../../../../services/http/configuration-http.service';
import { LookupCacheService } from '../../../../../services/deprecated/lookup-cache.service';
import { ToastService } from '../../../../../services/deprecated/toast.service';
import { DocumentRowOptions } from '../../../../static/components/document-row/document-row-options';
import { DocumentRowComponent } from '../../../../static/components/document-row/document-row.component';
import { SelectDocumentTypeComponent } from '../../../../static/components/select-document-type/select-document-type/select-document-type.component';
import { BaseWidget } from '../base-widget';
import { AbstractDocumentListFacade } from '../../../../../facade/abstract/abstract-document-list.facade';
import { StateCacheFacade } from '../../../../../facade/state-cache/state-cache.facade';

@Component({
    selector: 'fw-document-list-widget',
    templateUrl: './document-list-widget.component.html',
    host: { class: 'doc-list' },
})
export class DocumentListWidgetComponent
    extends BaseWidget
    implements OnInit, OnChanges, OnDestroy {
    public FormEditStyles = FormEditStyle;
    public documentList: DocumentListViewDTO[] = [];
    public documentOperation = DocumentOperation;
    public first: number = 0;
    public isLoading: boolean = true;
    
    public get isOperationInProgress(): boolean {
        return this._sharedState.operationInProgress;
    }

    public set isOperationInProgress(value: boolean) {
        this.stateCacheFacade.SetCache<SharedDocumentListWidgetState>(this.DocumentListWidgetConfiguration.StateGroupId, { 
            operationInProgress: value 
        });
    }
    
    public missingRequiredDocumentTypes: LookupModel[] = [];
    public rowOptions: DocumentRowOptions;
    public totalRecords: number = 0;
    public readyToLoad: boolean = false;
    public pageId: string;
    public uploadAttachmentId: string;
    public replaceAttachmentId: string;
    @Input()
    public DocumentListWidgetConfiguration: DocumentListWidgetConfigurationViewDTO;

    private _sharedState: SharedDocumentListWidgetState = { operationInProgress: false };
    private documentTypes: LookupModel[];
    private documentUploadConfiguration: DocumentUploadConfigurationViewDTO = null;
    private requiredDocumentTypes: LookupModel[] = [];
    private subcriptions: Subscription[] = [];

    private currentSelectedDocumentType: LookupModel;
    private currentDocumentListViewDTO: DocumentListViewDTO;
    private documentSensitivityId: string = '';

    @ViewChildren(DocumentRowComponent)
    private documentListChildren!: QueryList<DocumentRowComponent>;

    constructor(
        private lookupService: LookupCacheService,
        private dialogApplicationService: DialogApplicationService,
        private configurationService: ConfigurationHttpService,
        private translocoService: TranslocoService,
        private toastService: ToastService,
        public toastApplicationService: ToastApplicationService,
        public abstractDocumentFacade: AbstractDocumentListFacade,
        public elementRef: ElementRef,
        protected cdr: ChangeDetectorRef,
        private stateCacheFacade: StateCacheFacade
    ) {
        super(elementRef);
    }

    public loadDocuments() {
        if (this.abstractDocumentFacade._isPageLoaded === true) {

            this.abstractDocumentFacade.LoadDocuments(
                this.DocumentListWidgetConfiguration.StateGroupId,
                this.DocumentListWidgetConfiguration.Id,
                this.DocumentListWidgetConfiguration.Filters
            );
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.propagateChange(this.documentList);
    }

    ngOnDestroy(): void {
        if (this.subcriptions) {
            this.subcriptions.forEach((subcription) => {
                if (subcription) {
                    subcription.unsubscribe();
                }
            });
        }

        this.abstractDocumentFacade.Reset();
    }

    ngOnInit(): void {
        this.initialiseInputAttachmentFieldIds();

        if (this.DocumentListWidgetConfiguration.Add?.Details?.Properties) {
            const documentSensitivityListAddDetailsProperty = this.DocumentListWidgetConfiguration.Add.Details.Properties.find(property => property.PropertyName == 'SensitivityId');

            if (documentSensitivityListAddDetailsProperty && documentSensitivityListAddDetailsProperty.FixedValue) {
                this.documentSensitivityId = documentSensitivityListAddDetailsProperty.FixedValue
            }
        }

        this.pageId = this.abstractDocumentFacade._pageId;
        const reloadSubscription =
            this.abstractDocumentFacade.ReadyToLoadSubject.subscribe(
                (readyToLoad: boolean) => {
                    if (readyToLoad === true) {
                        this.loadDocuments();
                        this.readyToLoad = true;
                    }
                }
            );

        this.subcriptions.push(reloadSubscription);
        
        this.subcriptions.push(this.stateCacheFacade.GetCache<SharedDocumentListWidgetState>(this.DocumentListWidgetConfiguration.StateGroupId)
            .subscribe(data => {
                this._sharedState = data ?? { operationInProgress: false };
    
                if (this.documentListChildren) {
                    for (const row of this.documentListChildren) {
                        row.isOperationInProgress = this._sharedState.operationInProgress;
                    }
                }
            }));

        const getDocumentsSubscription = this.abstractDocumentFacade
            .GetDocuments()
            .subscribe((documentsResponse) => {
                //only continue execution if the dataSourceId, which triggered add/update of docs, is the current StateGroupId
                if (
                    !documentsResponse ||
                    (documentsResponse.triggeredDataSourceId &&
                        documentsResponse.triggeredDataSourceId !==
                        this.DocumentListWidgetConfiguration.StateGroupId)
                ) {
                    return;
                }

                const listStateEntry = documentsResponse.listState.find(
                    (alw) =>
                        alw.dataSourceId ==
                        this.DocumentListWidgetConfiguration.StateGroupId
                );

                if (listStateEntry || !documentsResponse.triggeredDataSourceId) {
                    if (
                        documentsResponse.widgetConfigurationId ===
                        this.DocumentListWidgetConfiguration.Id
                    ) {

                        if (listStateEntry.StateModel.DocumentAdded === true) {
                            this.toastApplicationService.showToast('DocumentList.DocumentAddedSuccessfully', 'DocumentList.DocumentAddedSuccessfully.Detail', severity_success);
                        }
                        else if (listStateEntry.StateModel.DocumentReplaced === true) {
                            this.toastApplicationService.showToast('DocumentList.DocumentReplacedSuccessfully', 'DocumentList.DocumentReplacedSuccessfully.Detail', severity_success);
                        }
                        else if (listStateEntry.StateModel.DocumentRemoved === true) {
                            this.toastApplicationService.showToast('DocumentList.DocumentRemovedSuccessfully', 'DocumentList.DocumentRemovedSuccessfully.Detail', severity_success);
                        }
                    }

                    if (listStateEntry) {
                        this.documentList = Object.assign(
                            [],
                            listStateEntry.StateModel.DocumentDrafts
                        );
                    } else {
                        this.documentList = Object.assign([], []);
                    }

                    this.documentList = this.abstractDocumentFacade.LoadRequiredDocuments(
                        this.DocumentListWidgetConfiguration.DocumentTypes,
                        this.documentList
                    );

                    this.loadDocumentTypes();
                    this.rowOptions = {
                        documentOperations:
                            this.DocumentListWidgetConfiguration.AllowedOperations,
                        fields: this.DocumentListWidgetConfiguration.Fields,
                        formEditStyle: this.abstractDocumentFacade.FormEditStyle,
                    };

                    if (this.documentListChildren) {
                        this.documentList.forEach((document) => {
                            const documentRow = this.getDocumentRowById(document.Id);
                            if (documentRow) {
                                documentRow.isLoading = false;
                            }
                        });
                    }

                    this.totalRecords = 100;
                    this.isLoading = false;

                    this.isOperationInProgress = false;
                }
            });

        this.subcriptions.push(getDocumentsSubscription);

        const GetAllowedFileTypesSubscription = this.configurationService
            .GetAllowedFileTypesConfiguration()
            .subscribe((documentUploadConfig) => {
                this.documentUploadConfiguration = documentUploadConfig;
                this.documentUploadConfiguration.AllowedFileTypes =
                    documentUploadConfig.AllowedFileTypes.map((aft) => aft.toLowerCase());
            });

        this.subcriptions.push(GetAllowedFileTypesSubscription);
    }

    public onAddClicked() {
        if (this.documentTypes.length == 1) {
            const fileInput: HTMLInputElement = this.getFileElement(
                this.uploadAttachmentId
            );
            this.currentSelectedDocumentType = this.documentTypes[0];
            this.handleFileUpload(fileInput);
            if (fileInput.files.length === 0) {
                fileInput.click();
            } else {
                fileInput.dispatchEvent(new Event('change'));
            }
        } else {
            const dialogOptions: DialogOptions<LookupModel[]> = {
                closable: false,
                showHeader: true,
                footer: '',
                header: this.translocoService.translate(
                    'SelectDocumentType.Form.Title'
                ),
                dataModel: this.documentTypes,
            };

            const dialogRef = this.dialogApplicationService.showFormDialog(
                SelectDocumentTypeComponent,
                dialogOptions
            );

            const dialogSubscription = dialogRef.onClose.subscribe(
                (selectedDocumentType: LookupModel) => {
                    if (selectedDocumentType) {
                        const fileInput: HTMLInputElement = this.getFileElement(
                            this.uploadAttachmentId
                        );
                        this.currentSelectedDocumentType = selectedDocumentType;
                        this.handleFileUpload(fileInput);
                        if (fileInput.files.length === 0) {
                            fileInput.click();
                        } else {
                            fileInput.dispatchEvent(new Event('change'));
                        }
                    }
                }
            );

            this.subcriptions.push(dialogSubscription);
        }
    }

    public onChange(): void {
        this.documentList = [...this.documentList];
        this.cdr.detectChanges();
        this.propagateChange(this.documentList);
        this.propagateTouch();
    }

    public operationEnabled(operation: DocumentOperation): boolean {
        return (
            this.DocumentListWidgetConfiguration.AllowedOperations.includes(
                operation
            ) && !this.isLoading
        );
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public propagateChange: any = () => { };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public propagateTouch: any = () => { };

    public rowRemoveEventHandler(
        documentId: string
    ): void {
        const documentRow = this.getDocumentRowById(documentId);
        documentRow.isLoading = true;
        
        this.isOperationInProgress = true;
        this.abstractDocumentFacade.RemoveDocument(documentId, this.DocumentListWidgetConfiguration.StateGroupId, this.DocumentListWidgetConfiguration.Id);
    }

    public rowReplaceEventHandler(
        documentListViewDTO: DocumentListViewDTO
    ): void {
        const fileInput: HTMLInputElement = this.getFileElement(
            this.replaceAttachmentId
        );
        this.currentDocumentListViewDTO = documentListViewDTO;
        this.handleFileReplace(fileInput);

        if (fileInput.files.length === 0) {
            fileInput.click();
        } else {
            fileInput.dispatchEvent(new Event('change'));
        }
    }

    public rowUploadEventHandler(documentListViewDTO: DocumentListViewDTO): void {
        this.currentSelectedDocumentType = this.documentTypes.find((docType) =>
            GuidHelper.Equals(documentListViewDTO.DocumentTypeId, docType.Id)
        );

        if (this.currentSelectedDocumentType) {
            const fileInput: HTMLInputElement = this.getFileElement(
                this.replaceAttachmentId
            );
            this.handleFileUpload(fileInput);

            if (fileInput.files.length === 0) {
                fileInput.click();
            } else {
                fileInput.dispatchEvent(new Event('change'));
            }
        }
    }

    private getDocumentRowById(documentId: string): DocumentRowComponent {
        return this.documentListChildren.find((row) => {
            return GuidHelper.Equals(
                row.DocumentListViewDTO.Id,
                documentId
            );
        });
    }

    private getDocumentRowByTypeId(documentTypeId: string, documentId: string): DocumentRowComponent {
        return this.documentListChildren.find((row) => {
            return (
                GuidHelper.Equals(
                    row.DocumentListViewDTO.DocumentTypeId,
                    documentTypeId
                ) &&
                GuidHelper.Equals(
                    row.DocumentListViewDTO.Id,
                    documentId
                )
            );
        });
    }

    private getFileElement(id: string): HTMLInputElement {
        const fileElement: HTMLInputElement = document.getElementById(
            id
        ) as HTMLInputElement;
        return fileElement;
    }
    private initialiseInputAttachmentFieldIds() {
        this.uploadAttachmentId = `uploadAttachment_${GuidHelper.NewGuid()}`;
        this.replaceAttachmentId = `replaceAttachment_${GuidHelper.NewGuid()}`;
    }

    private handleFileReplace(fileInput: HTMLInputElement): void {
        fileInput.accept = this.documentUploadConfiguration.AllowedFileTypes.map(
            (item) => {
                return '.' + item;
            }
        ).toString();

        fileInput.onchange = (event: Event) => {
            const files: FileList = (event.target as HTMLInputElement).files;

            if (files.length === 0) {
                return;
            }

            if (files.length > 1) {
                this.toastApplicationService.showToast('DocumentList.MoreThanOneFileSelected', 'DocumentList.MoreThanOneFileSelected.Detail', severity_warning);

                this.documentList.shift();
                this.onChange();
            } else {
                const selectedFile = files.item(0);
                if (this.validateSelectedFile(selectedFile)) {
                    const documentViewDTO: DocumentViewDTO = {
                        Id: this.currentDocumentListViewDTO.Id,
                        DateUploaded: new Date().toJSON(),
                        DocumentType: this.currentDocumentListViewDTO.DocumentType,
                        DocumentTypeId: this.currentDocumentListViewDTO.DocumentTypeId,
                        SensitivityId: this.currentDocumentListViewDTO.SensitivityId,
                        Size: selectedFile.size,
                        DocumentTypeDescription: '',
                        LastUpdatedDate: new Date().toJSON(),
                        FileName: selectedFile.name,
                        IsDownloadable: true,
                    };

                    const documentRow = this.getDocumentRowById(documentViewDTO.Id);

                    this.isOperationInProgress = true;

                    documentRow.isLoading = true;

                    const index = this.documentList.findIndex((doc) => {
                        return GuidHelper.Equals(
                            doc.Id,
                            documentRow.DocumentListViewDTO.Id
                        );
                    });

                    this.documentList.splice(index, 1);
                    this.documentList.unshift(documentViewDTO);

                    this.abstractDocumentFacade.UpdateDocument(
                        this.documentList,
                        documentViewDTO,
                        selectedFile,
                        this.DocumentListWidgetConfiguration.StateGroupId,
                        this.DocumentListWidgetConfiguration.Id
                    );
                }
            }

            (document.getElementById(fileInput.id) as HTMLInputElement).value = '';
        };
    }

    private handleFileUpload(fileInput: HTMLInputElement): void {
        fileInput.accept = this.documentUploadConfiguration.AllowedFileTypes.map(
            (item) => {
                return '.' + item;
            }
        ).toString();

        fileInput.onchange = (event: Event) => {
            const files: FileList = (event.target as HTMLInputElement).files;

            if (files.length === 0) {
                return;
            }

            if (files.length > 1) {
                this.toastApplicationService.showToast('DocumentList.MoreThanOneFileSelected', 'DocumentList.MoreThanOneFileSelected.Detail', severity_warning);

                this.documentList.shift();
                this.onChange();
            } else {
                const selectedFile = files.item(0);
                if (this.validateSelectedFile(selectedFile)) {
                    // Get a pre-populated row if there is one (has a type but no id)

                    let documentRow = this.getDocumentRowByTypeId(this.currentSelectedDocumentType.Id, GuidHelper.EmptyGuid());

                    const additionalDocument: DocumentListViewDTO = {
                        Id: GuidHelper.NewGuid(),
                        FileName: selectedFile.name,
                        DocumentTypeDescription: '',
                        LastUpdatedDate: new Date().toJSON(),
                        DocumentType: this.currentSelectedDocumentType.Value,
                        DocumentTypeId: this.currentSelectedDocumentType.Id,
                        SensitivityId: this.documentSensitivityId,
                        Size: selectedFile.size,
                        IsDownloadable: true,
                    };

                    // replace the row doc
                    if (documentRow) {
                        const index = this.documentList.findIndex((doc) => {
                            return (
                                GuidHelper.Equals(
                                    doc.DocumentTypeId,
                                    this.currentSelectedDocumentType.Id
                                ) && GuidHelper.Equals(doc.Id, GuidHelper.EmptyGuid())
                            );
                        });

                        this.documentList.splice(index, 1, additionalDocument);
                    } else {
                        this.documentList.unshift(additionalDocument);
                    }

                    this.onChange();

                    documentRow = this.getDocumentRowById(additionalDocument.Id);
                    
                    this.isOperationInProgress = true;

                    documentRow.isLoading = true;

                    const documentViewDTO: DocumentViewDTO = {
                        Id: additionalDocument.Id,
                        DateUploaded: new Date().toJSON(),
                        DocumentType: this.currentSelectedDocumentType.Value,
                        DocumentTypeId: this.currentSelectedDocumentType.Id,
                        SensitivityId: additionalDocument.SensitivityId,
                        Size: selectedFile.size,
                        DocumentTypeDescription: '',
                        LastUpdatedDate: additionalDocument.LastUpdatedDate,
                        FileName: selectedFile.name,
                        IsDownloadable: true,
                    };

                    this.abstractDocumentFacade.SetValidity(
                        this.DocumentListWidgetConfiguration.StateGroupId,
                        false
                    );

                    this.abstractDocumentFacade.AddDocument(
                        this.documentList,
                        documentViewDTO,
                        selectedFile,
                        this.DocumentListWidgetConfiguration.StateGroupId,
                        this.DocumentListWidgetConfiguration.Id
                    );
                }
            }

            (document.getElementById(fileInput.id) as HTMLInputElement).value = '';
        };
    }

    private loadDocumentTypes() {
        const getLookupTableSubscrription = this.lookupService
            .GetLandfolioLookupTable(
                LandfolioLookupTables[LandfolioLookupTables.DocumentType]
            )
            .subscribe((documentTypes) => {
                this.documentTypes = documentTypes;

                if (this.DocumentListWidgetConfiguration.DocumentTypes.length > 0) {
                    this.documentTypes = [];
                    this.requiredDocumentTypes = [];

                    //remove duplicate document types if so configured
                    this.DocumentListWidgetConfiguration.DocumentTypes =
                        this.DocumentListWidgetConfiguration.DocumentTypes.filter(
                            (value, index, array) => {
                                return (
                                    array.findIndex((docType) =>
                                        GuidHelper.Equals(
                                            docType.DocumentTypeId,
                                            value.DocumentTypeId
                                        )
                                    ) === index
                                );
                            }
                        );

                    this.DocumentListWidgetConfiguration.DocumentTypes.forEach(
                        (allowedDocumentType) => {
                            const documentType = documentTypes.find((docType) =>
                                GuidHelper.Equals(
                                    allowedDocumentType.DocumentTypeId,
                                    docType.Id
                                )
                            );
                            this.documentTypes.push(documentType);
                            if (allowedDocumentType.Required) {
                                this.requiredDocumentTypes.push(documentType);
                            }
                        }
                    );
                }

                this.validateRequiredDocumentTypes();
                this.abstractDocumentFacade.SetValidity(
                    this.DocumentListWidgetConfiguration.StateGroupId,
                    this.missingRequiredDocumentTypes
                        ? this.missingRequiredDocumentTypes.length === 0
                        : true
                );
            });

        this.subcriptions.push(getLookupTableSubscrription);
    }

    private validateRequiredDocumentTypes() {
        if (
            this.DocumentListWidgetConfiguration.DocumentTypes.length > 0 &&
            this.requiredDocumentTypes.length > 0
        ) {
            this.missingRequiredDocumentTypes = this.requiredDocumentTypes.filter(
                (documentType) => {
                    return (
                        this.documentList.findIndex((document) => {
                            return (
                                GuidHelper.Equals(documentType.Id, document.DocumentTypeId) &&
                                document.Size > 0
                            );
                        }) < 0
                    );
                }
            );
        }
    }

    private validateSelectedFile(selectedFile: File): boolean {
        const fileExtension: string = FileHelper.getFileExtension(
            selectedFile.name
        );

        if (
            !this.documentUploadConfiguration.AllowedFileTypes.includes(
                fileExtension.toLowerCase()
            )
        ) {
            const allowedFileTypes =
                this.documentUploadConfiguration.AllowedFileTypes.map((item) => {
                    return ' ' + item;
                }).toString();

            this.toastService.ShowToast([
                {
                    Message: 'DocumentList.SelectedFileTypeNotAllowed',
                    RouterLink: null,
                    RouterText: null,
                    QueryParameters: null,
                    MessageParameters: { allowedFileTypes: allowedFileTypes },
                },
            ], StyleVariants.Warning);

            return false;
        }

        if (selectedFile.size === 0) {
            this.toastApplicationService.showToast('DocumentList.SelectedFileEmpty', 'DocumentList.SelectedFileEmpty.Detail', severity_warning);

            return false;
        }

        if (selectedFile.size > this.documentUploadConfiguration.MaximumFileSize) {
            const bytePipe = new BytePipe();
            const maxFileSize = bytePipe.transform(
                this.documentUploadConfiguration.MaximumFileSize
            );

            this.toastService.ShowToast([
                {
                    Message: 'DocumentList.MaximumAllowedFileSizeExceeded',
                    RouterLink: null,
                    RouterText: null,
                    QueryParameters: null,

                    MessageParameters: { maxFileSize: maxFileSize },
                },
            ], StyleVariants.Warning);

            return false;
        }

        return true;
    }
}

interface SharedDocumentListWidgetState {
    operationInProgress: boolean;
}
