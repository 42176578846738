import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DiscussionCommentsListFacade } from '../../../../../facade/discussion/discussion-comments-list.facade';
import { DiscussionCommentFacade } from '../../../../../facade/discussion/discussion-comment.facade';
import { DiscussionsFacade } from '../../../../../facade/discussion/discussions.facade';
import { DiscussionListWidgetConfigurationViewDTO } from '../../../../../data-transfer-objects/configuration/widgets/discussion-list-widget-configuration-view-dto';
import { DiscussionViewDTO } from '../../../../../data-transfer-objects/discussion/discussion-view-dto';
import { GuidHelper } from '../../../../../helpers/guid-helper';
import { ToastApplicationService } from '../../../../../services/application/toast-application.service';
import { AbstractDiscussionCommentsListFacade } from '../../../../../facade/abstract/abstract-discussion-comments-list.facade';
import { AbstractDiscussionTopicCommentFacade } from '../../../../../facade/abstract/abstract-discussion-topic-comment.facade';
import { AbstractDiscussionTopicListFacade } from '../../../../../facade/abstract/abstract-discussion-topic-list.facade';
import { BaseWidget } from '../base-widget';
import { AbstractDiscussionFacade } from '../../../../../facade/abstract/abstract-discussion-facade';

@Component({
    selector: 'fw-discussions-widget',
    templateUrl: './discussions-widget.component.html',
    providers: [
        { provide: AbstractDiscussionTopicCommentFacade, useClass: DiscussionCommentFacade },
        { provide: AbstractDiscussionCommentsListFacade, useClass: DiscussionCommentsListFacade },
        { provide: AbstractDiscussionTopicListFacade, useClass: DiscussionsFacade }
    ]
})
export class DiscussionsWidgetComponent extends BaseWidget implements OnInit, OnDestroy {

    public DataSourceId: string;
    @Input()
    public DiscussionListWidgetConfigurationViewDTO: DiscussionListWidgetConfigurationViewDTO;
    private subscription: Subscription;
        

    constructor(
        private topicCommentFacade: AbstractDiscussionTopicCommentFacade,
        private commentsListFacade: AbstractDiscussionCommentsListFacade,
        public topicListFacade: AbstractDiscussionTopicListFacade,
        private discussionFacade: AbstractDiscussionFacade,
        public elementRef: ElementRef,
        public toastApplicationService: ToastApplicationService
    ) {

        super(elementRef);
    }
    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    ngOnInit(): void {

        this.DataSourceId = GuidHelper.NewGuid();

        this.subscription = this.discussionFacade._setLoadSubject.subscribe((readyToLoad) => {
            if (readyToLoad === true) {
                this.topicListFacade._entityId = this.discussionFacade._entityId;
                this.topicListFacade.PageId = this.discussionFacade._pageId;
                this.topicListFacade._widgetId = this.DiscussionListWidgetConfigurationViewDTO.Id;
                this.topicListFacade._setLoadSubject.next(true);
            }
        });
    }

    LoadTopic(discussion: DiscussionViewDTO) {

        this.commentsListFacade._discussionId = discussion.Id;
        this.commentsListFacade._entityId = this.topicListFacade._entityId;
        this.commentsListFacade._pageId = this.topicListFacade.PageId;
        this.commentsListFacade._setLoadSubject.next(true);

        this.topicCommentFacade._discussion = discussion;
        this.topicCommentFacade._entityId = this.topicListFacade._entityId;
        this.topicCommentFacade._pageId = this.topicListFacade.PageId;
        this.topicCommentFacade._setLoadSubject.next(true);
    }
}
