import { Directive, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { CommoditiesWidgetConfigurationViewDTO } from '../../../data-transfer-objects/configuration/commodities-widget-configuration-view-dto';
import { LandfolioLookupTables } from '../../../enums/lookup-table-enum';
import { LookupCacheService } from '../../../services/deprecated/lookup-cache.service';
import { LookupModel } from '../../../models/lookup-model';
import { GuidHelper } from 'src/app/helpers/guid-helper';
import { CommodityViewDTO } from '../../../data-transfer-objects/commodity/commodity-view-dto';
import { FormEditStyle } from '../../../enums/configuration/form-edit-style';
import { ValidatableInputControlBase } from './input-controls/validatable-input-control-base';
import { AbstractCommoditiesFacade } from '../../../facade/abstract/abstract-commodities-facade';
import { AbstractControl } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';

@Directive()
export abstract class EditCommodityBaseComponent extends ValidatableInputControlBase<string[]> implements OnInit, OnDestroy {
    public FormEditStyles = FormEditStyle;

    Options: LookupModel[] = [];

    @Input()
    ChipsetLabel: string = '';

    @Input()
    public AcceptCommodityChanges: EventEmitter<{ optionId: string, add: boolean }> = new EventEmitter<{ optionId: string, add: boolean }>();

    @Input()
    Commodities: CommodityViewDTO[] = [];

    @Input()
    Configuration: CommoditiesWidgetConfigurationViewDTO = null;

    @Input()
    DataSourceId: string;

    @Input()
    public FormEditStyle: FormEditStyle;

    @Input()
    HeaderLabel: string = '';

    @Input()
    SubmitDraft: boolean = true;

    @Input()
    public ReadyToStorePreviousValue: BehaviorSubject<boolean>;
    private _subscriptions: Subscription[] = [];
    constructor(
        protected translocoService: TranslocoService,
        protected lookupCacheService: LookupCacheService,
        protected commoditiesFacade: AbstractCommoditiesFacade
    ) {
        super(translocoService);
    }

    ngOnDestroy(): void {
        if (this._subscriptions) {
            this._subscriptions.forEach((subcription) => {
                if (subcription) {
                    subcription.unsubscribe();
                }
            });
        }
    }

    GetCommodityName(commodityId: string): string {
        const commodity: LookupModel = this.Options.find(option => GuidHelper.Equals(option.Id, commodityId));
        if (commodity) {
            return commodity.Value;
        } else {
            return '';
        }
    }

    InitialiseLookup(): void {
        const getLandfolioLookupTableSubscription = this.lookupCacheService
            .GetLandfolioLookupTable(LandfolioLookupTables[LandfolioLookupTables.Commodity])
            .subscribe((lookupModels: LookupModel[]) => {
                if (this.Configuration.AllowedCommodityIds.length) {
                    this.Options = lookupModels.filter(lookupModel => {
                        return this.Configuration.AllowedCommodityIds
                            .map(commodity => commodity.toLowerCase())
                            .indexOf(lookupModel.Id.toLowerCase()) > -1;
                    });
                } else {
                    this.Options = lookupModels;
                }
                this.ConfigureCommodityValidation({
                    Required: this.Configuration.Required
                });

                this.commoditiesFacade.SetValidity(this.DataSourceId, this.CustomFormGroup.valid);
                
            });

        this._subscriptions.push(getLandfolioLookupTableSubscription);
    }

    RemoveChip(): void {
        this.onChange(this.CustomFormGroup.controls[this.PropertyName]);        
    }

    ngOnInit(): void {
        this.PropertyName = 'Commodities';

        this.InitialiseLookup();

        if (this.FormEditStyle === FormEditStyle.Inline) {
            const readyToStorePreviousValueSubscription = this.ReadyToStorePreviousValue.subscribe((ready: boolean) => {
                if (ready) {
                    this.StorePreviousValue();
                }
            });

            this._subscriptions.push(readyToStorePreviousValueSubscription);
        }
    }

    onChange(event: AbstractControl) {

        if (Array.isArray(event.value)) {
            const filteredOptions: LookupModel[] = this.Options.filter(option => event.value.indexOf(option.Id) > -1);
            filteredOptions.sort((optionA, optionB) => (optionA.Value > optionB.Value) ? 1 : -1);
            const sortedOptionIds: string[] = filteredOptions.map(option => option.Id);
            this.CustomFormGroup.controls[this.PropertyName].setValue(sortedOptionIds);
        } else {
            this.CustomFormGroup.controls[this.PropertyName].setValue(event.value);
        }
        if (this.SubmitDraft) {
            this.onAcceptChanges();
        }
    }    
}
